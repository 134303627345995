import { icon } from '@fortawesome/fontawesome-svg-core';
import { type DaturaDroneAssessmentRiskCategory } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import numbro from 'numbro';
import Vue from 'vue';

import { Getters as FieldGetters } from '@/fields/store/getters';
import type { HexCode } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { ColumnSettingsCheckboxOptional } from '@/shared/handsontable/renderers/types';
import { columnSorting as columnSortingDate } from '@/shared/handsontable/rework/cellTypes/date';
import { columnSorting as columnSortingDropdown } from '@/shared/handsontable/rework/cellTypes/dropdown';
import { ColumnSettingsDropdownOptional } from '@/shared/handsontable/rework/cellTypes/dropdown/types';
import { ColumnSettingsOptional } from '@/shared/handsontable/rework/cellTypes/optionalRenderer/types';
import defaultPlaceholderColumn from '@/shared/handsontable/rework/features/placeholderColumn/column';
import { FarmdokColumnSettings } from '@/shared/handsontable/rework/types';
import store from '@/store';

import CellValue = Handsontable.CellValue;

const t = Vue.i18n.translate;

export const id: Handsontable.ColumnSettings = {
  data: 'id',
  header: {
    title: 'ID',
  },
  hiddenInPageSettings: true,
  hidden: true,
  width: 200,
};

export const selectColumn: ColumnSettingsCheckboxOptional = {
  data: 'select',
  type: 'farmdok.checkbox.optional',
  noHeaderContextMenu: true,
  hiddenInPageSettings: true,
  hidden: false,
  width: 45,
  disableColumnResize: true,
};

export const createdOn: ColumnSettingsOptional = {
  data: 'creationDate',
  header: {
    title: t('Erstellt'),
  },
  readOnly: true,
  type: 'date',
  width: 120,
  hiddenInPageSettings: false,
  hidden: false,
  columnSorting: columnSortingDate,
  initialSortOrder: 'desc',
  className: 'bg-transparent',
};

export const fieldColumn: ColumnSettingsDropdownOptional = {
  data: 'fieldDropdownItem',
  type: 'farmdok.dropdown',
  header: {
    title: t('FSNr-Schlag'),
    description: Vue.i18n.translate('Feldstücknummer - Feldname'),
  },
  readOnly: false,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  className: 'bg-transparent',
  columnSorting: columnSortingDropdown,
  dropdown: {
    async getItems(visualRow, hot) {
      const processOrderId: string | undefined = hot.getDataAtRowProp(visualRow, 'processOrderId');
      if (!processOrderId) return [];

      return (store.getters['fields/dropdownItems'] as FieldGetters['dropdownItems'])(processOrderId);
    },
  },
  renderEmpty: () => false,
  renderer: 'farmdok.optional',
};

export const assessmentName: ColumnSettingsOptional = {
  data: 'assessmentName',
  type: 'text',
  header: {
    title: t('Assessment Name'),
  },
  readOnly: true,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  className: 'bg-transparent',
};

export const occurrence: ColumnSettingsOptional = {
  data: 'occurrencesPerHa',
  header: {
    title: t('Häufigkeit'),
    description: t('Vorkommen pro Hektar'),
  },
  type: 'numeric',
  readOnly: true,
  width: 120,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = numbro(value ?? 0).format({
      mantissa: 1,
    });
    td.classList.add('htRight');
    return td;
  },
};

export const total: ColumnSettingsOptional = {
  data: 'occurrencesTotal',
  header: {
    title: t('Gesamtanzahl'),
    description: t('Vorkommen am Feld'),
  },
  type: 'numeric',
  readOnly: true,
  width: 120,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = numbro(value ?? 0).format({
      mantissa: 0,
    });
    td.classList.add('htRight');
    return td;
  },
};

export const riskCategory: ColumnSettingsOptional = {
  data: 'riskCategory',
  header: {
    title: t('Risikokategorie'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 100,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    const [statusIcon] = icon({ prefix: 'fas', iconName: 'circle-dot' }).node;
    statusIcon.setAttribute('style', `color:${iconColor(value)}`);
    // eslint-disable-next-line no-param-reassign
    td.innerHTML = `<div class="d-flex justify-content-between">${value ?? '-'} ${statusIcon.outerHTML}</div>`;
    return td;
  },
};

function iconColor(value: DaturaDroneAssessmentRiskCategory): HexCode {
  switch (value) {
    case t('Low'):
      return '#3CC961';
    case t('Medium'):
      return '#D7930E';
    case t('High'):
      return '#FF614C';
    default:
      throw new Error(`State ${value} is not supported.`);
  }
}

export const link: ColumnSettingsOptional = {
  data: 'link',
  header: {
    title: t('Link'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  className: 'bg-transparent',
};

export const customerName: ColumnSettingsOptional = {
  data: 'customerName',
  header: {
    title: t('Kunde'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = value;
    return td;
  },
};

export const customerNumber: ColumnSettingsOptional = {
  data: 'customerNumber',
  header: {
    title: t('Kundennummer'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = value;
    return td;
  },
};

export const companyNumber: ColumnSettingsOptional = {
  data: 'farmNumber',
  header: {
    title: t('Betriebsnummer'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = value;
    return td;
  },
};

export const customerEmail: ColumnSettingsOptional = {
  data: 'customerEmail',
  header: {
    title: t('Kontakt E-Mail'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  columnSorting: columnSortingDate,
  initialSortOrder: 'desc',
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = value;
    return td;
  },
};

const placeholderColumn = { ...defaultPlaceholderColumn, width: 40 };

export const columns: FarmdokColumnSettings[] = [
  id,
  selectColumn,
  createdOn,
  fieldColumn,
  assessmentName,
  occurrence,
  total,
  riskCategory,
  link,
  customerName,
  customerNumber,
  companyNumber,
  customerEmail,
  placeholderColumn,
];

export default columns;
