import Vue from 'vue';
import { GetterTree } from 'vuex';

import { baseUrl } from '@/shared/constants';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

import type { TableDataAssessment } from '../handsontable/types';
import { subscribableStore, syncableStore } from './common';
import type { DaturaAssessmentEntry, DaturaAssessmentState } from './types';

export type Getters = SubscribableGetters<DaturaAssessmentEntry> & {
  list: DaturaAssessmentEntry[];
  tabular: TableDataAssessment[];
};

const getterTree: GetterTree<DaturaAssessmentState, RootState> = {
  ...subscribableStore.getters,
  ...syncableStore.getters,
  list(state: DaturaAssessmentState): DaturaAssessmentEntry[] {
    return Object.values<DaturaAssessmentEntry>(state.data);
  },
  tabular(state: DaturaAssessmentState, getters: Getters, rootState: RootState, rootGetters): TableDataAssessment[] {
    // TODO: keep the table state in store
    return getters.list.map((e) => {
      let customer;
      if (e.fieldId) {
        const field = rootState.fields.data[e.fieldId];
        customer = rootState.customers.data[field.fieldGroup.customerId ?? ''];
      }

      return {
        ...e,
        customerName: customer?.name || '',
        customerEmail: customer?.email || '',
        customerNumber: customer?.customerNumber || '',
        farmNumber: customer?.bdmId || '',
        occurrencesPerHa: e.additionalData.occurrencesPerHa,
        occurrencesTotal: e.additionalData.occurrencesTotal,
        assessmentName: e.additionalData.assessmentName,
        riskCategory: Vue.i18n.translate(e.additionalData.riskCategory),
        select: false,
        link: constructLink(e),
        // TODO: improve syntax
        fieldDropdownItem: rootGetters['fields/dropdownItem'](e.fieldId),
      };
    });
  },
};

function constructLink(assessment: DaturaAssessmentEntry): string {
  return assessment.files?.length ? `${origin}${baseUrl}datura/drone/viewer/${assessment.files[0].id}` : '';
}

export default getterTree;
