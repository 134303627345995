import { render, staticRenderFns } from "./StepUpload.vue?vue&type=template&id=35ab762e&scoped=true&"
import script from "./StepUpload.vue?vue&type=script&setup=true&lang=ts&"
export * from "./StepUpload.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ab762e",
  null
  
)

export default component.exports